.rd-handleCompleteInfo {
  position: relative;
  padding-left: calc(60px * 1.3);
  width: max-content;
  margin: auto;
  margin-top: 180px;
}

.rd-handleCompleteInfo > .anticon {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 60px;
  color: var(--theme-purple);
}

.rd-handleCompleteInfo-button-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  justify-content: left;
  align-items: flex-start;
}

.rd-elon-wrapper {
  transition: ease 0.5s;
  position: relative;
}

.rd-elon-wrapper:hover {
  transform: scale(1.1);
}

.rd-elon-img {
  width: 250px;
  display: inline-block;
  pointer-events: none;
  user-select: none;
}

#thisButton {
  display: grid;
  place-items: center;
}

.rd-handle-complete-wrapper {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: -100px;
}

.shine {
  position: absolute;
  z-index: 20 !important;
  top: 1px;
  right: 6px;
  width: 1px;
  height: 16px;
  background: #fff;
  transform: rotate(15deg);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
  animation: shine 10s infinite;
}
.shine:before,
.shine:after {
  content: "";
  display: block;
  position: absolute;
}
.shine:before {
  top: 1px;
  right: -2px;
  width: 1px;
  height: 16px;
  background: #fff;
  transform: rotate(-90deg);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
}
.shine:after {
  top: 6px;
  left: -2px;
  width: 5px;
  height: 5px;
  background: #fff;
  transform: rotate(45deg);
  box-shadow: 0 0 8px 5px rgba(255, 255, 255, 0.75);
}

@keyframes shine {
  0% {
    opacity: 0;
  }
  34% {
    opacity: 0;
    transform: rotate(15deg);
  }
  40% {
    opacity: 0.6;
    transform: rotate(620deg);
  }
  48%,
  100% {
    opacity: 0;
    transform: rotate(635deg);
  }
}
