.faucet-root {
  padding: 100px;
}

.faucet-token {
  padding-left: 5%;
  padding-bottom: 1%;
  padding-top: 2%
}

.faucet-token-title {
  margin-top: 60px;
}

.faucet-panel-div {
  padding-left: 3%;
  align-items: center;
  display: flex;
}

.faucet-text {
  font-size: 16pt;
}

.faucet-panel-button {
  padding-left: 10px;
}