.upload-page {
  width: "max-content";
  margin: auto;
  padding: 20;
  
  &-image-selector {
    padding-top: 5%;
    padding-bottom: 5%;
    align-items: flex-end;

    &-view {
      align-self: center;
      padding-bottom: 5%;
    }

    &-button{ 
    }
  }
}