.handleGrid-root {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 40px;
    justify-content: center;
}

.cropped {
  overflow: hidden;
  object-fit: cover;
  }