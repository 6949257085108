
.copy-root {
  margin: auto;
  width: 60%;
  padding-top: 20px;
  padding-bottom: 100px;
}

.copy-image-container {
  text-align: center;
}

.copy-image {
  border-radius: 5%;
  border-style: solid;
  border-color: aliceblue;
  margin: auto;
}

.copy-info {
  /* border-radius: 0%;
  border-style: solid;
  border-color: aliceblue; */
  padding-top: 20px;
  padding-left: 20px;
  margin: auto;
}

.ant-divider-horizontal.ant-divider-with-text  {
  border-top-color: white !important;
}

.ant-divider.ant-divider-horizontal{
  border-top-color: white !important;
}

.ant-divider-inner-text{
  color: white;
}

.copy-mintable-mintbtn {
  padding-left: 20px;
}

.copy-mintable-item-header {
  padding-bottom: 10px;
}

.ant-space-item {
  color: white;
}

.copy-panel-div {
  align-items: center;
  display: flex;
}

.copy-panel-div .transfer-input{
  width: 40%;
  margin-right: 2%;
  height: 40px;
}

.copy-panel-div .copy-panel-text{
  width: 40%;
  margin-right: 2%;
}

.copy-panel-button {
  padding: 10px;
}

.copy-error {
  text-align: center;
  margin: 10%;
}