.my-assets-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-right:130px; */
  margin-left: 8%;
}

.my-assets-btn {
  margin-right: 8%;
}