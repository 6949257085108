
@media screen and (max-width: 992px) {
    .rd-progressBar-root h1 {
        font-size: 20px !important;
    }

    .rd-progressBar-root h5 {
        font-size: 10px !important;
    }

    .rd-progress-counter {
        font-size: 10px;
    }
}