
.mintInfo-root {
  margin-bottom: 100px;
}

.mintInfo-statement-root {
  margin-bottom: 80px;
}

.mintInfo-statement {
  margin-bottom: 5%;
}

.mintInfo-statement-button {
  float: right;
}

.mintInfo-statement-hyperlink {
  text-decoration-line: underline;
  text-decoration-color: aliceblue;
}

.mintInfo-checkbox {
  margin-bottom: 100px;
}

.ant-form {
  color: var(--font-white);
  padding-bottom: 40px;
}

.ant-form-text{
  color: var(--font-white);
  font-size: 14px;
  font-weight: 600;
}

.ant-radio-wrapper {
  color: var(--font-white) !important;
}

.ant-radio-wrapper-disabled {
  color: var(--font-white) !important;
}

.ant-checkbox-wrapper {
  color: var(--font-white) !important;
  font-size: 16px;
  font-weight: 600;
}

.ant-form-item-label > label {
  color: var(--font-white) !important;
  font-size: 16px;
  font-weight: 600;
}

.ant-form label {
  font-weight: 600;
}

.confirm-button {
  float: right;
}

.form-divider {
  padding-top: 5%;
  padding-bottom: 3%;
}