#navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#logo {
    height: 50px;
}

#menu .rd-menuItem { 
    margin: 0 20px;
    padding-bottom: 2px;
}

#menu span.rd-menuItem {
    cursor: pointer;
    color: var(--font-white);
    font-size: 16px;
    font-weight: 600;
}

#menu span.rd-menuItem.active { 
    color: var(--theme-purple-light);
    transition: border-width 0.3s;
}

#menu .ant-btn-primary {
}

#menu-ham {
    display: none;
}

@media screen and (max-width: 992px) {
    #menu {
        display: none;
    }

    #menu-ham {
        display: block;
    }

    #menu-ham .anticon {
        color: var(--font-white);
        font-size: 20px;
    }
}

.copy-logo-icon {
  width: 28px;
  height: 28px;
  margin-bottom: 13px;
}

.copy-logo {
  align-items: center;
}