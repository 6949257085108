.image-display-root {
  width: 300px;
  height: 300px;

  .image-display {
    position: relative;

    img, video {
      border-radius: 4%;
    }

    .float-items {
      position: absolute;
      z-index: 10;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 2px;
      padding-bottom: 2px;
      background-color: rgb(103, 138, 253);
    
      color: aliceblue;
      border-radius: 6px;
      font-size: 10pt;
    }

    .creation-mintables {   
      margin-right: 10px;
      margin-bottom: 10px;
      bottom: 0;
      right: 0;
    }

    .creation-copies {
      margin-right: 10px;
      margin-bottom: 40px;
      bottom: 0;
      right: 0;
    }

    .creation-title {
      margin-left: 10px;
      margin-top: 10px;
      bottom: 1;
      right: 1;
      font-size: 11pt;
      font-weight: 600;
    }

    .creation-charater {
      bottom: 0;
      right: 0;
      margin-right: 5px;
      margin-bottom: 70px;
      font-weight: 700; 
      background-color: transparent;

      &-item {
        padding: 3px 7px 3px 7px;
        border-radius: 50%;
        margin-left: 6px;
        background-color: rgb(103, 138, 253);
        color: white;
      }
    }
  }
}