
.explore {
  margin: auto;
  width: 90vw;
  
  &-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 2%;

    // &-btn {
    //   margin-right: 8%;
    // }
    
    // &-input{
    //   margin-right: 8%;
    // }
    
    // &-input .transfer-input{
    //   width: 400px;
    //   height: 50px;
    // }
    
  }
}