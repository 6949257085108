#createPageRoot {   
    margin: auto;
    max-width: 1360px;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
}

#createPageInner {
    margin-left: 15px;
    margin-right: 15px;
}