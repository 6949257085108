#pagebtn {
  background-color:cornflowerblue !important; 
  /* color:brown !important; */
  border-color:lightskyblue !important;
}

.display-item {
  background-color: #1E1E1E !important;
  
  .ant-select-item-option-selected {
    background-color: #404040 !important
  }

  .ant-select-item {
    color: var(--font-white) !important;
  }
}