@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --background-color: #000000;
  --font-white: #ffffff;
  --font-grey: #ffffffAA;
  --theme-purple: #5829E3;
  --theme-purple-light: #8E94FF;
  --input-radius: 30px;
  --purple-one: #b8b0d1;
  --pink: #d89ff8;
  --cyan: #89f8ff;
  --orange: #f6c3ad;
  
  --text-gradient: linear-gradient(135deg, rgba(200,66,245,1) 0%, rgba(142,148,255,1) 80%, rgba(121,239,174,1) 100%);
  /* --text-gradient-2: linear-gradient(135deg, rgb(240, 150, 150) 0%, rgb(67, 224, 19) 80%, rgb(240, 225, 12) 100%); */
  --web-gradient: linear-gradient(90deg, rgb(179, 7, 141) 0%, rgba(142,148,255,1) 50%, rgba(121,239,174,1) 100%);
}

body {
  font-family: 'Raleway', sans-serif !important;
  background-color: var(--background-color) !important;
}

.ant-layout-header {
  line-height: 80px !important;
  height: 80px !important;
}

.ant-layout,
.ant-layout-header {
  background-color: var(--background-color) !important;
}

.ant-typography {
  color: var(--font-white) !important;
}

.ant-typography.rd-purple {
  color:  var(--theme-purple-light) !important; 
}

h1.ant-typography {
  font-weight: 700 !important; 
}

.gradient-text {
  font-size: 28px !important;
  background: var(--text-gradient);
  width: max-content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-title {
  font-size: 32px !important;
  background: var(--text-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-btn {
  border-radius: var(--input-radius) !important;
  font-weight: 700 !important;
  border-width: 2px !important;
}

.ant-btn.ant-btn-primary {
  background-color: var(--theme-purple) !important;
  color: var(--font-white) !important;
  border-color: var(--theme-purple) !important;
}

.ant-btn.ant-btn-primary:hover {
  background-color: var(--theme-purple-light) !important;
  border-color: var(--theme-purple-light) !important;
}

.ant-btn.ant-btn-primary[disabled] {
  opacity: 0.6;
}

.ant-btn.ant-btn-primary[disabled]:hover {
  background-color: var(--theme-purple) !important;
  border-color: var(--theme-purple) !important;
}

.ant-btn.ant-btn-default {
  background-color: transparent !important;
  color: var(--theme-purple-light) !important;
  border-color: var(--theme-purple-light) !important;
}

@media screen and (max-width: 992px) {
  .ant-btn.ant-btn-primary {
    font-size: 12px !important;
    padding: 4px 8px !important;
    height: 30px !important;
  }

  .ant-layout-header {
    padding: 0 20px !important;
    /* height: 40px !important; */
  }

  #logo {
    height: 35px !important;
  }
}

.rd-pageFlex {
  min-height: 100vh;
  min-width: 100vw;
  padding: 15px 30px;
  display: flex;
  align-items: center;
}

.rd-pageFlex-inner {
  width: 100%;
}

#HandlePageRoot {
    max-width: 1360px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}