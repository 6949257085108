.description-root {
  padding-top: 20px;
}

.description-root .paragraphs {
  font-size: 14pt;
  font-weight: 500;
  padding-left: 20px;
}

.description-root .list-item {
  font-size: 12pt;
  font-weight: 450;
}

.description-root li {
  color: white;
}