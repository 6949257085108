.asset-view-layered {
  position: relative;

  &-reveal-icon {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 1.2em;
    padding-left: 1%;
    padding-right: 1%;
    color: white;
    background-color: #5829E3;
    border-radius: 50%;

    :hover{
      color: lightblue;
    }
  }
}